// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blogi-index-js": () => import("./../../../src/pages/blogi/index.js" /* webpackChunkName: "component---src-pages-blogi-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-janne-js": () => import("./../../../src/pages/janne.js" /* webpackChunkName: "component---src-pages-janne-js" */),
  "component---src-pages-medialle-index-js": () => import("./../../../src/pages/medialle/index.js" /* webpackChunkName: "component---src-pages-medialle-index-js" */),
  "component---src-pages-osallistu-js": () => import("./../../../src/pages/osallistu.js" /* webpackChunkName: "component---src-pages-osallistu-js" */),
  "component---src-pages-videot-index-js": () => import("./../../../src/pages/videot/index.js" /* webpackChunkName: "component---src-pages-videot-index-js" */),
  "component---src-pages-yhteys-js": () => import("./../../../src/pages/yhteys.js" /* webpackChunkName: "component---src-pages-yhteys-js" */),
  "component---src-templates-blog-post-index-js": () => import("./../../../src/templates/blogPost/index.js" /* webpackChunkName: "component---src-templates-blog-post-index-js" */)
}

